import React, { useEffect, useRef, useState } from "react";
import logo from "./logo.svg";

import axios from "utils/axios";

const { kakao } = window;

// 지구 반지름 (미터)
const EARTH_RADIUS = 6371e3;

// 좌표 코드 생성 함수
function getGridCode(row: number, col: number): string {
  let letters = "";
  while (col >= 0) {
    letters = String.fromCharCode(65 + (col % 26)) + letters;
    col = Math.floor(col / 26) - 1;
  }
  const number = row + 1;
  return `${letters}${number}`;
}

// 각도를 라디안으로 변환
function toRad(value: number): number {
  return (value * Math.PI) / 180;
}

// 라디안을 각도로 변환
function toDeg(value: number): number {
  return (value * 180) / Math.PI;
}

// // 주어진 거리와 방향으로 좌표를 계산하는 함수
// function calculateLatLngOffset(
//   lat: number,
//   lng: number,
//   distance: number,
//   bearing: number
// ): { lat: number; lng: number } {
//   const δ = distance / EARTH_RADIUS; // 거리와 지구 반지름을 사용하여 각 거리 (라디안)
//   const θ = toRad(bearing); // 방향을 라디안으로 변환

//   const φ1 = toRad(lat); // 시작 위도를 라디안으로 변환
//   const λ1 = toRad(lng); // 시작 경도를 라디안으로 변환

//   // 새로운 위도 계산
//   const φ2 = Math.asin(Math.sin(φ1) * Math.cos(δ) + Math.cos(φ1) * Math.sin(δ) * Math.cos(θ));

//   // 새로운 경도 계산
//   const λ2 = λ1 + Math.atan2(Math.sin(θ) * Math.sin(δ) * Math.cos(φ1), Math.cos(δ) - Math.sin(φ1) * Math.sin(φ2));

//   return { lat: toDeg(φ2), lng: toDeg(λ2) };
// }
function calculateLatLngOffset(
  lat: number,
  lng: number,
  distance: number,
  bearing: number
): { lat: number; lng: number } {
  const δ = distance / EARTH_RADIUS; // 거리와 지구 반지름을 사용하여 각 거리 (라디안)
  const θ = toRad(bearing); // 방향을 라디안으로 변환

  const φ1 = toRad(lat); // 시작 위도를 라디안으로 변환
  const λ1 = toRad(lng); // 시작 경도를 라디안으로 변환

  const φ2 = φ1 + δ * Math.cos(θ); // 새로운 위도 계산 (Rhumb line)

  let Δλ = δ * Math.sin(θ) / Math.cos(φ1); // 경도 차이 계산 (Rhumb line)
  const λ2 = λ1 + Δλ;

  return { lat: toDeg(φ2), lng: toDeg(λ2) };
}

// 격자 네모를 그리기 위한 함수
function drawGridSquare(
  code: string,
  swLat: number,
  swLng: number,
  seLat: number,
  seLng: number,
  neLat: number,
  neLng: number,
  nwLat: number,
  nwLng: number
): any {
  const rectanglePath = [
    new kakao.maps.LatLng(swLat, swLng),
    new kakao.maps.LatLng(seLat, seLng),
    new kakao.maps.LatLng(neLat, neLng),
    new kakao.maps.LatLng(nwLat, nwLng),
    new kakao.maps.LatLng(swLat, swLng),
  ];

  const polygon = new kakao.maps.Polygon({
    path: rectanglePath,
    strokeWeight: 0.1,
    strokeColor: "#000",
    strokeOpacity: 1,
    strokeStyle: "solid",
    // fillColor: "#FF0000",
    fillOpacity: 0,
  });
  return polygon;
}

const MAP_OPTIONS = {
  center: new kakao.maps.LatLng(33.341695, 126.562487),
  level: 9,
};

function App() {
  const { kakao } = window;
  const mapRef = useRef<any>(null);
  const kakaomap = useRef<any>(null);

  const [mapView, setMapView] = useState<boolean>(false);

  useEffect(() => {
    setMapView(true);
    return () => {
      kakaomap.current = null;
      setMapView(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (mapView) {
      kakaomap.current = new kakao.maps.Map(mapRef.current, MAP_OPTIONS);

      const zoomControl = new kakao.maps.ZoomControl();
      kakaomap.current.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
      kakaomap.current.setMinLevel(3);
      kakaomap.current.setMaxLevel(10);
    }
    return () => {kakaomap.current = null}
  }, [mapView]);

  const gogosing = () => {
    const startPoint = { lat: 33.5613, lng: 126.1432 };

    // 격자 크기 (미터)
    const gridSize = 1000; // 1km
    const xDistance = 77000; // 동쪽으로의 거리 (미터)
    const yDistance = 51000; // 남쪽으로의 거리 (미터)

    const yCount = Math.ceil(yDistance / gridSize);
    const xCount = Math.ceil(xDistance / gridSize);

    // 네모를 그립니다.
    for (let i = 0; i < yCount; i++) {
      for (let j = 0; j < xCount; j++) {
        const sw = calculateLatLngOffset(startPoint.lat, startPoint.lng, i * gridSize, 180); // 남쪽 방향으로 이동
        const swLatLng = calculateLatLngOffset(sw.lat, sw.lng, j * gridSize, 90); // 동쪽 방향으로 이동

        const seLatLng = calculateLatLngOffset(swLatLng.lat, swLatLng.lng, gridSize, 90); // 동쪽으로 gridSize 이동
        const neLatLng = calculateLatLngOffset(seLatLng.lat, seLatLng.lng, gridSize, 0); // 북쪽으로 gridSize 이동
        const nwLatLng = calculateLatLngOffset(swLatLng.lat, swLatLng.lng, gridSize, 0); // 북쪽으로 gridSize 이동

        const gridCode = getGridCode(i, j);
        drawGridSquare(
          gridCode,
          swLatLng.lat,
          swLatLng.lng,
          seLatLng.lat,
          seLatLng.lng,
          neLatLng.lat,
          neLatLng.lng,
          nwLatLng.lat,
          nwLatLng.lng
        ).setMap(kakaomap.current);
      }
    }
  };

  const test2 = async () => {
    try {
      gogosing();
      // const response = await axios.get("/test");
      // console.log(response);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="App">
      <button style={{ position: "absolute", top: "60px", zIndex: 20, left: "50px" }} onClick={test2}>
        뻐튼
      </button>
      {mapView && (
        <div>
          <div ref={mapRef} id="map" style={{ width: "1350px", height: "800px" }}></div>
        </div>
      )}
    </div>
  );
}

export default App;
