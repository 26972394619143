import React, { useImperativeHandle, useMemo, useState, useRef, useEffect, forwardRef, useCallback } from 'react';

import styles from './style.module.scss';

import { tableInterface } from 'types';

import { Pagination } from './functions';

import { useDidMountEffect } from 'utils';

const Table = (props: tableInterface, ref: any) => {

    const [isLoading, setIsLoading] = useState(true);

    const [data, setData] = useState<any>(null);
    const [viewCnt, setViewCnt] = useState<number>(10);

    const tblColOrder = useRef<any>(null);

    const { colgroup, head, body } = props;

    const PAGER = useRef<Pagination>(new Pagination());
    const ORIGIN_DATA = useRef<any>(null);

    const initialData = useCallback((data?: any) => {
        if (!tblColOrder.current) {
            tblColOrder.current = [];
            head?.forEach((H1: any) => {
                let H2 = H1.filter((filter: any) => filter.col)?.map((M: any) => { return M.col });

                tblColOrder.current = [...tblColOrder.current, ...H2]
            })
        }

        if (!data && body) {
            data = body
        }

        const ROW = data?.map((D: any) => {
            return tblColOrder.current.map((M: any) => {
                return { value: D[M] ?? "", name: M }
            })
        })
        PAGER.current = new Pagination(ROW.length, viewCnt)
        ORIGIN_DATA.current = ROW;
        setPager();
    }, [props])

    const setPager = () => {
        setData(() => {
            const range = PAGER.current.getItemRange();
            let setData: any[] = [];
            for (let i = range.start; i < range.end; i++) {
                setData.push(ORIGIN_DATA.current[i]);
            }
            setIsLoading(false);
            return setData;
        })
    }

    useDidMountEffect(() => {
        initialData();
    }, [props])

    useImperativeHandle(
        ref,
        () => ({
            getData: () => {
                console.log("Hello, WSorld!");
            },
            setData: (data: any) => {
                initialData(data);
            }
        }),
    )

    const HEADER_RENDER = () => {
        return head?.map((inner: any, i: number) => {

            return <tr key={`tbl_header${i}`}>
                {
                    inner.map((h: any, j: number) => {

                        let { col, title, ...prop } = h;

                        return <th key={`tbl_header${i}_${j}_${title}`} data-col={col} {...prop} >
                            {title}
                        </th>
                    })
                }
            </tr>
        })
    }

    const header = useMemo(HEADER_RENDER, [props]);

    const tblbody = useMemo(() => {
        return data?.map((rowData: any, index: number) => {

            return <tr key={`tbl_row_${index}`}>
                {rowData.map((TD: any) => {
                    return <td key={`tbl_col_${index}_${TD.name}`}>{TD.value}</td>
                })}
            </tr>
        })
    }, undefined)

    const ChangePage = (data:any) => {
        PAGER.current.setPage(data.page);
        setPager()
    }

    const FOOTER_RENDER = () => {
        if (tblColOrder.current) {
            const range = PAGER.current.getPageRange();
            console.log(range);
            let renderData = [];

            if (range.prevPage > 0) {
                renderData.push(
                    <li
                        key={"pagination_prev"}
                        aria-hidden={true}
                        onClick={() => {ChangePage({page: range.prevPage})}}
                    >
                        {"<"}
                    </li>
                );
            }
            for (let i = range.blockBegin; i <= range.blockEnd; i++) {
                renderData.push(
                    <li
                        key={"pagination" + i}
                        className={i === range.page ? styles.pageON : undefined}
                        onClick={() => {ChangePage({page: i})}}
                        aria-hidden={true}
                    >
                        {i}
                    </li>
                );
            }
            if (range.nextPage > 0) {
                renderData.push(
                    <li
                        key={"pagination_next"}
                        aria-hidden={true}
                        onClick={() => {ChangePage({page: range.nextPage})}}
                    >
                        {">"}
                    </li>
                );
            }


            return <tr>
                <td colSpan={tblColOrder.current.length}>
                    <ul className={`${styles.paginationUL}`}>
                        {renderData.map(e => {
                            return e
                        })}
                    </ul>
                </td>
            </tr>
        }
    }

    const footer = useMemo(FOOTER_RENDER, undefined)

    const ChangeViewCnt = (value: any) => {
        setIsLoading(true);
        setViewCnt(value);
        PAGER.current.setViewCnt(value);
        setPager();
    }

    if (isLoading) return <div className={`${styles.tableWrapper}`}>Loading</div>
    else return <div className={`${styles.tableWrapper}`}>
        <div className={`${styles.viewPart}`}>
            <select onChange={({ target }) => { ChangeViewCnt(target?.value) }} value={viewCnt}>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
            </select><span>개씩 보기</span>
        </div>
        <table className={`${styles.table}`}>
            <thead>
                {header}
            </thead>
            <tbody>
                {tblbody}
            </tbody>
            <tfoot>
                {footer}
            </tfoot>
        </table>
    </div>
}

export default forwardRef(Table);