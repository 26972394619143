import React, { useEffect, useState, useRef } from "react";
import styles from "./display.module.scss";
import { useParams } from "react-router-dom";

import Clock from "./clock";
import Main from "./main";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Marquee from "react-fast-marquee";

import useSocket from "utils/socket.io";

const MESSAGE_SET = [
  [],
  ["집중호우", "주의요망"],
  ["중산간", "폭우발생", "홍수위험"],
  ["안전구역", "신속한", "대피바람"],
  ["홍수발생", "통행금지", "즉시대피"],
];

const Display = (props: any) => {
  let { id } = useParams();

  const io = useRef<any>(null);
  const cnt = useRef<number>(0);
  const intervalRef = useRef<any>(null);
  const [warning, setWarning] = useState<any>(null);

  const [text, setText] = useState<any>([]);

  const { isConnected, sendMessage, socket } = useSocket(id);

  useEffect(() => {
    const refresh = setTimeout(() => {
      window.location.reload();
    }, 1000 * 60 * 30);

    if (socket) {
      socket.on("danger", (msg: any) => {
        if (isNaN(parseInt(msg))) msg = 0;

        setWarning(msg);
      });
    }

    return () => {
      clearTimeout(refresh);
      if (socket) {
        socket.off("danger");
      }
    };
  }, [socket]);

  useEffect(() => {
    if (warning > 0) {
      cnt.current = 0;
      setText([MESSAGE_SET?.[warning]?.[cnt.current]]);
      intervalRef.current = setInterval(() => {
        cnt.current++;
        if (cnt.current >= MESSAGE_SET?.[warning]?.length) {
          clearInterval(intervalRef.current);
          return;
        }
        setText((OLD: any) => [...OLD, MESSAGE_SET?.[warning]?.[cnt.current]]);
      }, 500);
    } else {
      cnt.current = 0;
    }
  }, [warning]);

  const fontsize = { fontSize: "10px" };

  return (
    <div className={`${styles.container}`} style={fontsize}>
      {warning > 0 ? (
        <>
          <div className={`${styles.warn}`}>
            <div className={`${styles.icon}`}>
              <FontAwesomeIcon icon={faTriangleExclamation} />
            </div>

            {text?.map((row: any, index: number) => {
              return (
                <div className={`${styles.dangerbox}`}>
                  <Marquee speed={40} autoFill={true}>
                    <p className={`${styles.message}`}>
                      <span>{row}</span>
                    </p>
                  </Marquee>
                </div>
              );
            })}

            {/* <div className={`${styles.dangerbox}`}>
              <Marquee speed={40} autoFill={true}>
                <p className={`${styles.message}`} style={{ marginLeft: "10px", marginRight: "10px" }}>
                  <span>! DANGER !</span>
                </p>
              </Marquee>
            </div>
            <div className={`${styles.dangerbox}`}>
              <Marquee speed={40} autoFill={true}>
                <p className={`${styles.message}`} style={{ marginLeft: "20px" }}>
                  <span>! 危险 !</span>
                </p>
              </Marquee>
            </div> */}
          </div>
          <audio autoPlay loop src="/api/audio/danger"></audio>
        </>
      ) : (
        <>
          <div className={`${styles.title}`}>
            <Clock />
          </div>
          <Main id={id} />
        </>
      )}
    </div>
  );
};

export default Display;
