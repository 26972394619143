import React, { useState, useMemo, useEffect } from "react";
import styles from "./safe.module.css";
import BreadCrumb from "../2.Breadcrumb";

import Table from 'utils/Table';

const head = [[
    { title: "번호", col: "no" },
    { title: "안전 정보 ID", col: "safeId" },
    { title: "유형", col: "type" },
    { title: "위험 수준 값", col: "dangerVal" },
    { title: "문자 안전 정보", col: "textWarn" },
    { title: "음성 안전 정보", col: "soundWarn" },
    { title: "등록일", col: "createDt" },
    { title: "변경일", col: "udpateDt" },
    { title: "현황", col: "status" },
    { title: "비고", col: "etc" },
],
[

]
]
const body: any = [
    { no: 1, safeId: "MSG-001", type: "ALL", dangerVal: 1, textWarn: "홍수 지역 입니다. 신속히 대피하세요.", soundWarn: "홍수 지역입니다. 신속히 대피하세요.", createDt: "2023-12-01", updateDt: "", status: "사용", etc: "" },
    { no: 2, safeId: "MSG-002", type: "TXT", dangerVal: 1, textWarn: "홍수 지역 입니다. 신속히 대피하세요.", soundWarn: "홍수 지역입니다. 신속히 대피하세요.", createDt: "2023-12-01", updateDt: "", status: "사용", etc: "" },
    { no: 3, safeId: "MSG-003", type: "VCE", dangerVal: 1, textWarn: "홍수 지역 입니다. 신속히 대피하세요.", soundWarn: "홍수 지역입니다. 신속히 대피하세요.", createDt: "2023-12-01", updateDt: "", status: "사용", etc: "" },
    { no: 4, safeId: "MSG-004", type: "ALL", dangerVal: 1, textWarn: "홍수 지역 입니다. 신속히 대피하세요.", soundWarn: "홍수 지역입니다. 신속히 대피하세요.", createDt: "2023-12-01", updateDt: "", status: "사용", etc: "" },
    { no: 5, safeId: "MSG-005", type: "ALL", dangerVal: 1, textWarn: "홍수 지역 입니다. 신속히 대피하세요.", soundWarn: "홍수 지역입니다. 신속히 대피하세요.", createDt: "2023-12-01", updateDt: "", status: "사용", etc: "" },
    { no: 6, safeId: "MSG-006", type: "ALL", dangerVal: 1, textWarn: "홍수 지역 입니다. 신속히 대피하세요.", soundWarn: "홍수 지역입니다. 신속히 대피하세요.", createDt: "2023-12-01", updateDt: "", status: "사용", etc: "" },
]


const SafeManagement = (props:any) => {

    const [ tableData, setTableData ] = useState<any>(null);

    useEffect(() => {
        const d = new Array(368).fill(null).map((rowData, index) => {
            return { no: index, safeId: "MSG-"+index, type: "ALL", dangerVal: 1, textWarn: "홍수 지역 입니다. 신속히 대피하세요.", soundWarn: "홍수 지역입니다. 신속히 대피하세요.", createDt: "2023-12-01", updateDt: "", status: "사용", etc: "" }
        })
        setTableData(d);
    },[])

    return (
        <main>
            <BreadCrumb breadcrumb={props.breadcrumb} />
            <div className={`common-title-box`}>
                <div>
                    <h2>안전 정보 관리</h2>
                    <p>각종 안전 정보를 관리 할 수 있습니다.</p>
                </div>
            </div>
            <div>
                
            </div>
            <div>
                <Table head={head} body={tableData} />
            </div>
        </main>
    );
};

export default SafeManagement;