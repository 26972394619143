import React, { useEffect, useRef, useState, createContext, useCallback } from "react";
import styles from "./main.module.scss";

import BreadCrumb from "../2.Breadcrumb";
import { BreadCrumbItem } from "../../types";
import LeftMenu from "./Left";
import Map from './Map';

import axios from "utils/axios";
import { AnySoaRecord } from "dns";
import Timeline from "./Timeline";

import { useDidMountEffect } from 'utils';

declare var kakao: any;

interface InfoData {
    id: number,
    createDt: any,
    warnInfo: any
}

export const MainContext = createContext({
    // floodData: [],
    // setFloodDataHandler: (data: any) => { },
    infoData: {
        id: undefined, createDt: undefined, warnInfo: { flood: 0, flood_p: 0, marker: 0, marker_p: 0 }
    },
    setInfoDataHandler: (data: any) => { },
    setRefresh: (data: any) => { }
});

const Main = (props: any) => {
    const [infoData, setInfoData] = useState<any>(null);
    const [refresh, setRefresh] = useState<any>(null);

    const setInfoDataHandler = (data: any) => setInfoData(data);

    const getFloodData = async (id?: string) => {
        const response = await axios.post('/main/getInfoData', { id: infoData?.id });
        const row = response.data?.data;
        setInfoData({ id: row.id, createDt: row.createDt, warnInfo: row.infoData })
    };


    useEffect(() => {
        getFloodData();
    }, [])

    return (
        <MainContext.Provider value={{ setInfoDataHandler, infoData, setRefresh }}>
            <main>
                <BreadCrumb breadcrumb={props.breadcrumb} />
                <div className={`${styles.MainContainer}`}>
                    {/* <LeftMenu /> */}

                    <div className={`${styles.Main}`}>
                        <Map refresh={new Date().toString()} kakao={kakao} />
                    </div>
                    {/* {
                        infoData?.id && <Timeline />
                    } */}
                </div>
            </main>
        </MainContext.Provider>
    );
};

export default Main;

