import React from 'react';
import styles from './breadcrumb.module.css'
import { BreadCrumbItem, BreadCrumbItems } from '../../types';

interface BreadProps {
    breadcrumb?: BreadCrumbItems
}

const BreadCrumb = (props:BreadProps) => {
    
    return <nav className={`${styles.breadcrumb}`}>
        {
            props.breadcrumb?.map((item:BreadCrumbItem) => {
                return <div className={`${styles.breadcrumbItem}`} key={`breadcrumb_${item.name}`}>
                    {item.name}
                </div>
            })
        }
    </nav>
}

export default BreadCrumb
