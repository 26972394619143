// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb_breadcrumb__\\+B-HB {
    font-size: 0.9rem;
    display: flex;
    gap: 15px;
    margin-bottom: 7px;
    padding-left: 10px;
}

.breadcrumb_breadcrumbItem__2zPtv {
    flex: 0 0 auto;
    position: relative;
}
.breadcrumb_breadcrumbItem__2zPtv:not(:last-of-type)::after {
    content: ">";
    position: absolute;
    top: 0;
    left: calc(100% + 4px);

}
`, "",{"version":3,"sources":["webpack://./src/pages/2.Breadcrumb/breadcrumb.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,kBAAkB;IAClB,MAAM;IACN,sBAAsB;;AAE1B","sourcesContent":[".breadcrumb {\n    font-size: 0.9rem;\n    display: flex;\n    gap: 15px;\n    margin-bottom: 7px;\n    padding-left: 10px;\n}\n\n.breadcrumbItem {\n    flex: 0 0 auto;\n    position: relative;\n}\n.breadcrumbItem:not(:last-of-type)::after {\n    content: \">\";\n    position: absolute;\n    top: 0;\n    left: calc(100% + 4px);\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": `breadcrumb_breadcrumb__+B-HB`,
	"breadcrumbItem": `breadcrumb_breadcrumbItem__2zPtv`
};
export default ___CSS_LOADER_EXPORT___;
