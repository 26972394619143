import React, { useRef, useState, useEffect, useCallback } from "react";
import { io } from "socket.io-client";

const useSocket = (id?: string) => {
  const socketRef = useRef<any>(null);
  const [isConnected, setIsConnected] = useState(false);
  const pingIntervalRef = useRef<any>(null);

  useEffect(() => {
    const connect = () => {
      socketRef.current = io("/", {
        // 기본 경로를 통해 연결
        path: "/socket.io",
        reconnectionAttempts: Infinity, // 최대 재연결 시도 횟수
        reconnectionDelay: 5000, // 재연결 시도 간격 (밀리초)
        reconnectionDelayMax: 10000, // 최대 재연결 시도 간격 (밀리초)
        timeout: 20000 // 연결 타임아웃 (밀리초)
      });

      socketRef.current.on("connect", () => {
        console.log("Socket connected");
        setIsConnected(true);
        startPing();
        if (id) {
          socketRef.current.emit("join", id?.match(/\d/) ? "PROD" : id.toString().toUpperCase());
        }
      });

      socketRef.current.on("disconnect", () => {
        console.log("Socket disconnected");
        setIsConnected(false);
      });

      socketRef.current.on("connect_error", (error: any) => {
        console.error("Socket connection error:", error);
      });
    };

    connect();

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (socketRef.current && socketRef.current.disconnected) {
          console.log('Reconnecting socket...');
          socketRef.current.connect(); // 수동으로 재연결 시도
        }
      } else {
        if (socketRef.current) {
          clearInterval(pingIntervalRef.current);
          socketRef.current.disconnect();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (socketRef.current) {
        stopPing();
        socketRef.current.disconnect();
      }
    };
  }, []);

  const startPing = useCallback(() => {
    pingIntervalRef.current = setInterval(() => {
      if (socketRef.current && socketRef.current.connected) {
        socketRef.current.emit('ping');
      }
    }, 30000); // 30초마다 ping 메시지 전송
  }, []);

  const stopPing = useCallback(() => {
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
    }
  },[])

  const sendMessage = useCallback((message:any) => {
    if (socketRef.current && socketRef.current.connected) {
      socketRef.current.emit('message', message);
    }
  }, []);

  

  return { isConnected, sendMessage, socket: socketRef.current };
};

export default useSocket;
