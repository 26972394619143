import React from "react";
import styles from "./header.module.scss";
import { Link } from "react-router-dom";

const TITLE = "안전지원 통합 시스템 v1.0";

const Header = () => {
    return (
        <header className={`${styles.header}`}>
            <div className={`${styles.headerItem}`}></div>
            <div className={`${styles.headerItem}`}>{TITLE}</div>
            <div className={`${styles.headerItem}`}>
                <div className={`${styles.btnGroup}`}>
                    <div>
                        <Link to="/login">로그인</Link>
                    </div>
                    <div>
                        <Link to="/mypage">마이페이지</Link>
                    </div>
                    <div>
                        <Link to="/">사이트맵</Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;

