import React from "react";
import styles from "./menu.module.scss";
import { Link } from "react-router-dom";

const Hamburger = () => {
    return <div className={`${styles.Hamburger}`}></div>;
};

const NavItem = (props: any) => {
    return (
        <Link className={`${styles.navItem}`}
        to={props.path}
        >
            <p>{props.children}</p>
        </Link>
    );
};

const Menu = () => {
    return (
        <nav className={`${styles.menu}`}>
            <Hamburger />
            <div className={`${styles.menuInner}`}>
                <NavItem path="/dashboard">위험 상황 모니터링</NavItem>
                <NavItem path="/flood-management">홍수/침수 정보 관리</NavItem>
                <NavItem path="/safe-management">안전 정보 관리</NavItem>
                <NavItem path="/system-management">시스템 관리</NavItem>
            </div>
        </nav>
    );
};

export default Menu;

