// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_box__UCtEG {
  width: 80%;
  min-width: 100px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 50px;
  gap: 10px;
  margin-bottom: 30px;
}
.style_box__UCtEG .style_item__ERHCa > button {
  width: 100%;
  height: 100%;
  border: 2px solid #AAA;
  border-radius: 3px;
  background-color: transparent;
}
.style_box__UCtEG .style_item__ERHCa > button.style_active__hm-Wr {
  background-color: black;
  color: white;
}
.style_box__UCtEG .style_item__ERHCa.style_danger__7elE2 > button {
  width: 100%;
  border: 2px solid red;
  border-radius: 3px;
  background-color: transparent;
  color: red;
}
.style_box__UCtEG .style_item__ERHCa.style_danger__7elE2 > button.style_active__hm-Wr {
  background-color: red;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/81.DisplayTest/style.module.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,gBAAA;EACA,cAAA;EACA,aAAA;EACA,8BAAA;EACA,oBAAA;EACA,SAAA;EACA,mBAAA;AACJ;AAAI;EACI,WAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;EACA,6BAAA;AAER;AADQ;EACI,uBAAA;EACA,YAAA;AAGZ;AAAI;EACI,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,6BAAA;EACA,UAAA;AAER;AADQ;EACI,qBAAA;EACA,YAAA;AAGZ","sourcesContent":[".box {\n    width: 80%;\n    min-width: 100px;\n    margin: 0 auto;\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-auto-rows: 50px;\n    gap: 10px;\n    margin-bottom: 30px;\n    & .item > button {\n        width: 100%;\n        height: 100%;\n        border: 2px solid #AAA;\n        border-radius: 3px;\n        background-color: transparent;\n        &.active {\n            background-color: black;\n            color: white;\n        }\n    }\n    & .item.danger > button {\n        width: 100%;\n        border: 2px solid red;\n        border-radius: 3px;\n        background-color: transparent;\n        color: red;\n        &.active {\n            background-color: red;\n            color: white;\n        }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `style_box__UCtEG`,
	"item": `style_item__ERHCa`,
	"active": `style_active__hm-Wr`,
	"danger": `style_danger__7elE2`
};
export default ___CSS_LOADER_EXPORT___;
