import React, { useEffect, useRef, useState, useContext, useCallback } from "react";
import styles from "./main.module.scss";

import axios from "utils/axios";

import { InitialGrid, getFloodData, getMarkersData } from "./functions";

import { MainContext } from ".";

import TimeLines from "./Timeline";

const { kakao } = window;

let tm_out: any;


const MAP_OPTIONS = {
    center: new kakao.maps.LatLng(33.371695, 126.562487),
    level: 9,
};

const Map = (props: any) => {
    const mapRef = useRef<any>(null);
    const markerRef = useRef<any>([]);
    const gridRef = useRef<any>([]);
    const floodRef = useRef<any>([]);

    const [showGrid, setShowGrid] = useState<boolean>(true);
    const [showFlood, setShowFlood] = useState<boolean>(true);
    const [showMarker, setShowMarker] = useState<boolean>(false);

    const { infoData } = useContext(MainContext);



    const map_changed = useCallback(() => {
        clearTimeout(tm_out);
        tm_out = setTimeout(() => {
            const level = mapRef.current.getLevel();
            const bounds = mapRef.current.getBounds();
            
            initialGridHandler({...bounds, level});
        }, 500)
    }, [showGrid]);

    const initialGridHandler = async (bounds?:any) => {
        gridRef.current?.forEach((rectangleBounds: any) => {
            rectangleBounds.setMap(null);
        });

        gridRef.current = await InitialGrid(bounds, mapRef.current);
        gridRender(showGrid);
    }

   

    const initialMap = () => {
        mapRef.current?.setCenter(MAP_OPTIONS.center);
        mapRef.current?.setLevel(MAP_OPTIONS.level);
    }

    useEffect(() => {
        if (!mapRef.current) {
            const container = document.getElementById("map");


            mapRef.current = new kakao.maps.Map(container, MAP_OPTIONS);
            const zoomControl = new kakao.maps.ZoomControl();
            mapRef.current.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
            mapRef.current.setMinLevel(2);
        }

        const windowOnClickHandler = (event: any) => {
            const { target } = event;
            const t = target?.closest("[data-ss]");
            if (!t) return;
            const type = t.dataset.type;
            if (type === "infowindow-close") {
                const close = document.querySelector("img[alt='close']") as HTMLElement;
                close?.click();
            }
        };

        initialGridHandler();

        // kakao.maps.event.addListener(mapRef.current, 'center_changed', map_changed)

        document.addEventListener("click", windowOnClickHandler);

        return () => {
            document.removeEventListener("click", windowOnClickHandler);
            // kakao.maps.event.removeListener(mapRef.current, 'center_changed', map_changed);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const gridRender = (show:boolean) => {
        
        if (show) {
            
            gridRef.current?.forEach((rectangleBounds: any) => {
                
                rectangleBounds.setMap(mapRef.current);
            });
        } else {
            gridRef.current?.forEach((rectangleBounds: any) => {
                rectangleBounds.setMap(null);
            });
        }
    }

    useEffect(() => {
        kakao.maps.event.addListener(mapRef.current, 'bounds_changed', map_changed)

        gridRender(showGrid);

        return () => {
            gridRender(false);
            kakao.maps.event.removeListener(mapRef.current, 'bounds_changed', map_changed);
        }
    }, [showGrid]);

    const getWHDataHandler = async () => {
        floodRef.current = await getFloodData(mapRef.current, infoData);
    };

    useEffect(() => {
        
        floodRef.current?.forEach((rectangleBounds: any) => {
            rectangleBounds.setMap(null);
        });
        if (showFlood && infoData) {

            getWHDataHandler();
        }
    }, [showFlood, infoData]);

    const getMarkersDataHandler = async () => {
        markerRef.current = await getMarkersData(mapRef.current);
    };

    useEffect(() => {
        markerRef.current.forEach((i: any) => {
            i.marker?.setMap(null);
            i.infowindow?.close();
        });
        if (showMarker) {
            getMarkersDataHandler();
        }
    }, [showMarker]);

    return (
        <div style={{ position: "relative" }}>
            <div className={`${styles.MapTop}`} aria-hidden="true">
                {
                    infoData?.warnInfo && (<>
                        <b>홍수/침수 지역:</b> 총 {infoData.warnInfo?.flood}({infoData.warnInfo?.flood_p}%), <b>장애 장비:</b> 총 {infoData.warnInfo.marker}({infoData.warnInfo.marker_p}%)
                    </>)
                }

            </div>

            <div id="map" className={`${styles.Map}`}></div>
            <div className={`${styles.MapBtnBoxes}`}>
                <button onClick={initialMap}>초기화</button> 
                <button onClick={() => setShowGrid((show) => !show)}>GRID {showGrid ? `HIDE` : `SHOW`}</button>
                <button onClick={() => setShowFlood((show) => !show)}>Water {showFlood ? `HIDE` : `SHOW`}</button>
                <button onClick={() => setShowMarker((show) => !show)}>Marker {showMarker ? `HIDE` : `SHOW`}</button>
            </div>


        </div>
    );
};

export default Map;

