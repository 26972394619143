import React, { useEffect, useState } from "react";
import axios from "axios";

import useSocket from "utils/socket.io";

import styles from "./style.module.scss";
import { useParams } from "react-router-dom";

const TApp = () => {
  let { id } = useParams();
  if(!id) id = "dev";
  const [Danger, setDanger] = useState<number>(0);
  const { isConnected, sendMessage, socket } = useSocket(id);
  useEffect(() => {
    if (socket) {
      socket.on("danger", (msg: any) => {
        if (isNaN(parseInt(msg))) msg = 0;
        setDanger(msg);
      });
    }
    return () => {
      if (socket) {
        socket.off("danger");
      }
    };
  }, [socket]);

  const clickHandler = async (level: number) => {
    const response = await axios.get("/api/test/danger/"+id+"/" + level);
    setDanger(level);
  };


  return (
    <>
      <div className={`${styles.box}`}>
        {new Array(4).fill(null).map((_, i) => {
          return (
            <div className={`${styles.item}`} key={"btn" + i}>
              <button className={`${Danger === i + 1 ? styles.active : ""}`} onClick={() => clickHandler(i + 1)}>
                Type {i + 1}
              </button>
            </div>
          );
        })}
      </div>
      <div className={`${styles.box}`} style={{gridTemplateColumns: "1fr"}}>
        <div className={`${styles.item} ${styles.danger} `}>
          <button className={`${Danger > 0 ? styles.active : ""}`} onClick={() => clickHandler(0)}>
            OFF
          </button>
        </div>
      </div>
    </>
  );
};

export default TApp;
