import React, {useEffect, useState, useRef } from 'react';
import moment from "moment";
import styles from "./display.module.scss";

const Clock = (props: any) => {
    const [curTime, setCurTime] = useState<any>({
      Y: "",
      M: "",
      D: "",
      H: "",
      I: "",
      S: "",
      W: "",
    });
  
    const timeRef = useRef<any>(null);
    useEffect(() => {
      timeRef.current = setInterval(() => {
        let now = moment();
        let Y, M, D, H, I, S, W;
        Y = now.format("YY년");
        M = now.format("M월");
        D = now.format("D일");
        H = now.format("HH");
        I = now.format("mm");
        S = now.format("ss");
        switch (now.day()) {
          case 0:
            W = "(일)";
            break;
          case 1:
            W = "(월)";
            break;
          case 2:
            W = "(화)";
            break;
          case 3:
            W = "(수)";
            break;
          case 4:
            W = "(목)";
            break;
          case 5:
            W = "(금)";
            break;
          case 6:
            W = "(토)";
            break;
        }
        setCurTime({ Y, M, D, H, I, S, W });
      }, 1000);
  
      return () => {
        clearInterval(timeRef.current);
      };
    }, []);
    return (
      <>
        {curTime.M && (
          <>
            <p className={`${styles.date}`}>
              <span>{curTime.M}</span>
              <span>{curTime.D}</span>
              <span>{curTime.W}</span>
            </p>
            <p className={`${styles.time}`}>
              <span>{curTime.H}</span>
              <span>:</span>
              <span>{curTime.I}</span>
              <span>:</span>
              <span>{curTime.S}</span>
            </p>
          </>
        )}
      </>
    );
  };

  export default Clock;