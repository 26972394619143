// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu_menu__0ISYk {
  position: fixed;
  top: 30px;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 100;
  background-color: var(--white);
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #999;
}

.menu_menuInner__x2GdF {
  flex: 1 0 auto;
  display: flex;
  gap: 10px;
  margin-left: 10px;
}

.menu_Hamburger__ie7do {
  flex: 0 0 40px;
}

.menu_navItem__UP-L1 {
  flex: 0 0 200px;
  padding: 5px;
  text-align: center;
  background-color: var(--menu-color);
  font-size: 1rem;
  text-decoration: none;
}
.menu_navItem__UP-L1 p {
  margin: 0;
}

.menu_menuInner__x2GdF {
  flex: 1 0 auto;
  display: flex;
  gap: 10px;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/1.Menu/menu.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,SAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,8BAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,6BAAA;AACJ;;AACA;EACI,cAAA;EACA,aAAA;EACA,SAAA;EACA,iBAAA;AAEJ;;AACA;EACI,cAAA;AAEJ;;AAAA;EACI,eAAA;EACA,YAAA;EACA,kBAAA;EACA,mCAAA;EACA,eAAA;EACA,qBAAA;AAGJ;AAFI;EACI,SAAA;AAIR;;AAAA;EACI,cAAA;EACA,aAAA;EACA,SAAA;EACA,iBAAA;AAGJ","sourcesContent":[".menu {\n    position: fixed;\n    top: 30px;\n    left: 0;\n    width: 100%;\n    height: 40px;\n    z-index: 100;\n    background-color: var(--white);\n    margin-bottom: 5px;\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n    border-bottom: 1px solid #999;\n}\n.menuInner {\n    flex: 1 0 auto;\n    display: flex;\n    gap: 10px;\n    margin-left: 10px;\n}\n\n.Hamburger {\n    flex: 0 0 40px;\n}\n.navItem {\n    flex: 0 0 200px;\n    padding: 5px;\n    text-align: center;\n    background-color: var(--menu-color);\n    font-size: 1rem;\n    text-decoration: none;\n    & p {\n        margin: 0;\n        \n    }\n}\n.menuInner {\n    flex: 1 0 auto;\n    display: flex;\n    gap: 10px;\n    margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `menu_menu__0ISYk`,
	"menuInner": `menu_menuInner__x2GdF`,
	"Hamburger": `menu_Hamburger__ie7do`,
	"navItem": `menu_navItem__UP-L1`
};
export default ___CSS_LOADER_EXPORT___;
