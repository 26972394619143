// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_tableWrapper__N6Is7 {
  width: 100%;
}

.style_viewPart__SeDdb {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 5px;
  margin-bottom: 5px;
}

.style_table__E9xPn {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.style_table__E9xPn.style_fixed__YbA3m {
  table-layout: fixed;
}
.style_table__E9xPn > thead > tr > td, .style_table__E9xPn > thead > tr > th, .style_table__E9xPn > tbody > tr > td, .style_table__E9xPn > tbody > tr > th {
  padding: 0.3rem;
  border-bottom: 1px solid #999;
  border-right: 1px solid #999;
}
.style_table__E9xPn > thead > tr > td:first-of-type, .style_table__E9xPn > thead > tr > th:first-of-type, .style_table__E9xPn > tbody > tr > td:first-of-type, .style_table__E9xPn > tbody > tr > th:first-of-type {
  border-left: 1px solid #999;
}
.style_table__E9xPn > thead > tr:first-of-type > td, .style_table__E9xPn > thead > tr:first-of-type > th, .style_table__E9xPn > tbody > tr:first-of-type > td, .style_table__E9xPn > tbody > tr:first-of-type > th {
  border-top: 1px solid #999;
}
.style_table__E9xPn > tfoot td, .style_table__E9xPn > tfoot th {
  border: none;
}
.style_table__E9xPn > tfoot td ul.style_paginationUL__mtUvl, .style_table__E9xPn > tfoot th ul.style_paginationUL__mtUvl {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.style_table__E9xPn > tfoot td ul.style_paginationUL__mtUvl li, .style_table__E9xPn > tfoot th ul.style_paginationUL__mtUvl li {
  font-size: 1.2rem;
  cursor: pointer;
}
.style_table__E9xPn > tfoot td ul.style_paginationUL__mtUvl li.style_pageON__T6JVJ, .style_table__E9xPn > tfoot th ul.style_paginationUL__mtUvl li.style_pageON__T6JVJ {
  font-weight: bold;
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/utils/Table/style.module.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;AADJ;;AAIA;EACI,aAAA;EACA,uBAAA;EAAA,kBAAA;EACA,eAAA;EACA,kBAAA;AADJ;;AAIA;EAEI,WAAA;EACA,yBAAA;EACA,iBAAA;AAFJ;AADI;EAAU,mBAAA;AAId;AAEY;EACI,eAAA;EACA,6BAtBP;EAuBO,4BAvBP;AAuBT;AACgB;EACI,2BAzBX;AA0BT;AAGgB;EACI,0BA9BX;AA6BT;AAQQ;EACI,YAAA;AANZ;AAOY;EACI,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;AALhB;AAMgB;EACI,iBAAA;EACA,eAAA;AAJpB;AAKoB;EACI,iBAAA;EACA,UAAA;AAHxB","sourcesContent":["$border: 1px solid #999;\n\n.tableWrapper {\n    width: 100%;\n}\n\n.viewPart {\n    display: flex;\n    width: fit-content;\n    margin-top: 5px;\n    margin-bottom: 5px;\n}\n\n.table {\n    &.fixed { table-layout: fixed }\n    width: 100%;\n    border-collapse: collapse;\n    border-spacing: 0;\n    & > thead, & > tbody {\n        & > tr {\n            & > td, & > th {\n                padding: .3rem;\n                border-bottom: $border;\n                border-right: $border;\n                &:first-of-type {\n                    border-left: $border;\n                }\n            }\n            &:first-of-type {\n                & > td, & > th {\n                    border-top: $border;\n                }\n            }\n            \n        }\n    }\n    & > tfoot {\n        & td, & th {\n            border: none;\n            & ul.paginationUL {\n                list-style: none;\n                display: flex;\n                justify-content: center;\n                align-items: center;\n                gap: 10px;\n                & li {\n                    font-size: 1.2rem;\n                    cursor: pointer;\n                    &.pageON {\n                        font-weight: bold;\n                        color: red\n                    }\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableWrapper": `style_tableWrapper__N6Is7`,
	"viewPart": `style_viewPart__SeDdb`,
	"table": `style_table__E9xPn`,
	"fixed": `style_fixed__YbA3m`,
	"paginationUL": `style_paginationUL__mtUvl`,
	"pageON": `style_pageON__T6JVJ`
};
export default ___CSS_LOADER_EXPORT___;
