

import React from "react";
import { Route, Routes, Outlet, BrowserRouter } from "react-router-dom";

const Page = () => {
    return <div>Hello, World!</div>
}

const SafeManagement = (props:any) => {
    return (

        <Routes>
            <Route index element={<Page />} />
        </Routes>

    );
};

export default SafeManagement;

