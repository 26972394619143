import axios from "utils/axios";
const { kakao } = window;

const IW_CONTENT: string = `
<div class="ss-kakao-infowindow" style="
    width: 148px;
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
">
    <div>설치 ID: POS_015</div>
    <div style="flex: 1 1 auto">
        <table class="ss-infowindow-table">
            <tbody>
                <tr>
                    <td>위치 ID</td>
                    <td>ABCDEF_12345</td>
                </tr>
                <tr>
                    <td>자산 ID</td>
                    <td>ABCDEF_12345</td>
                </tr>
                <tr>
                    <td>IP 주소</td>
                    <td>ABCDEF_12345</td>
                </tr>
                <tr>
                    <td>설치 정보</td>
                    <td>ABCDEF_12345</td>
                </tr>
                <tr>
                    <td>설치일</td>
                    <td>ABCDEF_12345</td>
                </tr>
                <tr>
                    <td>설치자</td>
                    <td>ABCDEF_12345</td>
                </tr>
                <tr>
                    <td>발생일</td>
                    <td>ABCDEF_12345</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div>
        <button data-ss='true' data-type='infowindow-reg'>장애 등록</button>
        <button data-ss='true' data-type='infowindow-close'>닫기</button>
    </div>
</div>
`;

const InitialGrid = async (bounds: any, map:any) => {
    const gridRef: any[] = [];
    let postData = bounds;

    const customOverlay = new kakao.maps.CustomOverlay({});

    const request = await axios.post("/map/getGrid", postData);
    const { data } = request?.data;
    for (const i of data) {
        const sw: any = new kakao.maps.LatLng(i.sLat, i.sLon),
            ne: any = new kakao.maps.LatLng(i.eLat, i.eLon);
        const rect = new kakao.maps.LatLngBounds(sw, ne);

        const rectangleBounds = new kakao.maps.Rectangle({
            bounds: rect,
            strokeOpacity: 0.5,
            strokeWeight: 0.5,
            // strokeColor: "#47F",
            strokeColor: "#999",
            fillColor: "#FFF",
            fillOpacity: 0,
            
        });

        gridRef.push(rectangleBounds);
    }
    return gridRef;
};

const getFloodData = async (map: any, selectedId: any) => {
    const FloodRef: any[] = [];
    const request = await axios.post("/map/getFlood", { id: selectedId?.id });
    const { data } = request.data;
    for (const i of data) {
        const sw: any = new kakao.maps.LatLng(i.sLat, i.sLon),
            ne: any = new kakao.maps.LatLng(i.eLat, i.eLon);
        const rect = new kakao.maps.LatLngBounds(sw, ne);
        const opacity = (i.level * i.level * 0.13).toFixed(2);
        const rectangleBounds = new kakao.maps.Rectangle({
            bounds: rect,
            strokeOpacity: opacity,
            strokeWeight: 0.5,
            strokeColor: "#c43f4b",
            fillColor: "#ff0017",
            fillOpacity: opacity,
            zIndex: "8",
        });
        rectangleBounds.setMap(map);
        kakao.maps.event.addListener(rectangleBounds, "click", function (mouseEvent: any) {});
        FloodRef.push(rectangleBounds);
    }
    return FloodRef;
};

let imageSrc = "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png", // 마커이미지의 주소입니다
    imageSize = new kakao.maps.Size(30, 50), // 마커이미지의 크기입니다
    imageOption = { offset: new kakao.maps.Point(15, 50) }, // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
    markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);

const getMarkersData = async (map: any) => {
    const markerRef: any[] = [];
    const request = await axios.post("/map/getMarkers");
    const { data } = request.data;

    const iwRemoveable = true;

    const infowindow = new kakao.maps.InfoWindow({
        removable: iwRemoveable,
    });
    for (const i of data) {
        const latlng = new kakao.maps.LatLng(i.lat, i.lon);
        let marker: any;
        if (i.status > 0) {
            marker = new kakao.maps.Marker({ position: latlng });
        } else {
            marker = new kakao.maps.Marker({ position: latlng, image: markerImage });
        }
        marker.setClickable(true);
        marker.setMap(map);

        kakao.maps.event.addListener(marker, "click", function (mouseEvent: any) {
            const iwContent = IW_CONTENT;
            infowindow.setContent(iwContent);

            markerRef?.forEach((i: any) => {
                i.infowindow?.close();
            });
            infowindow.open(map, marker);
        });
        markerRef.push({ marker, infowindow });
    }
    return markerRef;
};

export { InitialGrid, getFloodData, getMarkersData };
