import React, { useEffect, useState, useRef } from "react";
import axios from "utils/axios";
import moment from "moment";
import styles from "./display.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDroplet, faTemperatureHalf, faUmbrella, faWind } from "@fortawesome/free-solid-svg-icons";

interface Props {
  id: number | string | undefined;
}

const Main = ({ id }: Props) => {
  const errRef = useRef<number>(0);
  const tmoutRef = useRef<any>(null);

  const [weather, setWeather] = useState<any>({
    weather: "",
    weatherCode: "",
    temperature: "",
    humidity: "",
    windSpeed: "",
  });

  const getWeather = async () => {
    try {
      const response = await axios.get("/display/getWeather");

      const data = response.data;

      const time: number = parseInt(moment().format("HHmm"));

      let png: string = "";
      try {
        png = data.weatherCode;
        if (parseInt(data.weatherCode) < 3) {
          png += "-";
          if (time > 1800) {
            png += "2";
          } else {
            png += "1";
          }
        }
      } catch (e) {
        console.error(e);
        png = "1";
      }

      const icon = require("./assets/" + png + ".png");

      errRef.current = 0;
      setWeather({ ...data, icon });
    } catch (e) {
      console.error(e);
      if (errRef.current < 5) {
        errRef.current++;
        getWeather();
      }
    }
  };

  useEffect(() => {
    clearInterval(tmoutRef.current);
    tmoutRef.current = setTimeout(getWeather, 1000 * 60 * 5);
    getWeather();
    return () => {
      clearInterval(tmoutRef.current);
    };
  }, []);

  return (
    <>
      <div className={`${styles.image}`}>
        <img src={weather.icon} />
      </div>
      <div className={`${styles.message}`}>
        <table>
          <tbody>
            <tr>
              <td>
                <FontAwesomeIcon icon={faTemperatureHalf} />
              </td>
              <td>{weather.temperature}</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faWind} />
              </td>
              <td>{weather.windSpeed}</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faUmbrella} />
              </td>
              <td>{weather.rain}</td>
            </tr>
            <tr>
              <td>
                <FontAwesomeIcon icon={faDroplet} />
              </td>
              <td>{weather.humidity}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Main;
