// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header__QbLMt {
  position: fixed;
  top: 0;
  left: 0;
  height: 30px;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-color);
  color: var(--white);
  text-align: center;
}

.header_headerItem__7ylQU {
  flex: 0 0 25%;
  font-size: 1.3rem;
}

.header_btnGroup__DPthd {
  display: flex;
  justify-content: flex-end;
  font-size: 1rem;
  padding-right: 30px;
}
.header_btnGroup__DPthd > div {
  flex: 0 0 70px;
}
.header_btnGroup__DPthd > div:not(:last-of-type) {
  border-right: 1px solid var(--white);
}
.header_btnGroup__DPthd > div > a {
  color: var(--white);
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/0.Header/header.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iCAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AACA;EACI,aAAA;EACA,iBAAA;AAEJ;;AAAA;EACI,aAAA;EACA,yBAAA;EACA,eAAA;EACA,mBAAA;AAGJ;AAFI;EACI,cAAA;AAIR;AAHQ;EACI,oCAAA;AAKZ;AAHQ;EACI,mBAAA;EACA,qBAAA;AAKZ","sourcesContent":[".header {\n    position: fixed;\n    top: 0;\n    left: 0;\n    height: 30px;\n    width: 100%;\n    z-index: 100;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: var(--bg-color);\n    color: var(--white);\n    text-align: center;\n}\n.headerItem {\n    flex: 0 0 25%;\n    font-size: 1.3rem;\n}\n.btnGroup {\n    display: flex;\n    justify-content: flex-end;\n    font-size: 1rem;\n    padding-right: 30px;\n    & > div {\n        flex: 0 0 70px;\n        &:not(:last-of-type) {\n            border-right: 1px solid var(--white);\n        }\n        & > a {\n            color: var(--white);\n            text-decoration: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__QbLMt`,
	"headerItem": `header_headerItem__7ylQU`,
	"btnGroup": `header_btnGroup__DPthd`
};
export default ___CSS_LOADER_EXPORT___;
