import React, { useRef, useEffect} from 'react';

import axios from 'utils/axios';
// import { socket } from 'utils/socket.io';

// let socketId: string|null;

const Index = () => {
    const socketRef = useRef<any>(null);
    

    const send = () => {
        // socketRef.current.emit("message", "메시지 보낸다 !!!!");
    }

    useEffect(() => {
        // socketRef.current = socket();
        // socketRef.current.on("id-receive", (id:string) => {
        //     socketId = id;
        //     socketRef.current.emit("join", "DEV");
        // })

        // socketRef.current.on("message", (msg:string) => {
        //     alert(msg);
        // })

        // socketRef.current.on("refresh", (msg:string) => {
        //     window.location.reload();
        // })
        // return () => {
        //     socketId = null;
        //     socketRef.current.off("id-receive");
        //     socketRef.current.disconnect();
        // }
    },[])



    const test = async () => {
        // const response = await axios.get("/test/id/"+socketId);
        const response = await axios.get("/test/danger");
        console.log(response);
    }

    const test2 = async () => {
        // const response = await axios.get("/test/id/"+socketId);
        const response = await axios.get("/test/dangerOff");
        console.log(response);
    }

    return <>
        <h1>Hello, World!</h1>
        <button onClick={send}>ㄲㄲ</button>
        <button onClick={test}>danger</button>
        <button onClick={test2}>danger off</button>
    </>
}

export default Index;