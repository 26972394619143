export class Pagination {
    page: number;
    totalCnt: number;
    viewCnt: number;
    viewPage: number;
    // totalPage: number

    constructor(totalCnt:number = 0, viewCnt:number = 10, viewPage:number = 10) {
        this.totalCnt = totalCnt;
        this.viewCnt = viewCnt;
        this.viewPage = viewPage;
        this.page = 1;
        // this.totalPage = totalCnt % viewCnt > 0 ? Math.floor(totalCnt / viewCnt) + 1 : Math.floor(totalCnt / viewCnt);
    }

    getSelf():any {
        return this;
    }

    setTotalCnt(totalCnt:number):void {
        this.totalCnt = totalCnt
    }

    setPage(page:number):void {
        this.page = page;
    }

    setViewCnt(viewCnt:number):void {
        this.viewCnt = viewCnt;
        // this.totalPage = 
    }

    getPage():number {
        return this.page;
    }

    getTotalCnt():number {
        return this.totalCnt;
    }
    getViewCnt():number {
        return this.viewCnt;
    }

    getItemRange():any {
        let start = (this.page - 1) * this.viewCnt;
        if (start < 0) {
            start = 0;
        }
        let end = this.viewCnt * this.page;
        if (end > this.totalCnt) {
            end = this.totalCnt;
        }
        return { start, end };
    }
    getPageRange():any {
        let totalPage = this.totalCnt % this.viewCnt > 0 ? Math.floor(this.totalCnt / this.viewCnt) + 1 : Math.floor(this.totalCnt / this.viewCnt);

        let curBlock = Math.floor((this.page - 1) / this.viewPage) + 1;
        let blockBegin = (curBlock - 1) * this.viewPage + 1;
        let blockEnd = blockBegin + this.viewPage - 1;
        if (blockEnd > totalPage) blockEnd = totalPage;
        let nextPage = totalPage > blockEnd ? blockEnd + 1 : 0;
        let prevPage = curBlock > 1 ? blockBegin - 1 : 0;
        return { page: this.page, curBlock, blockBegin, blockEnd, nextPage, prevPage, totalPage };
    }
    getIndexNO():number {
        return this.viewCnt + (this.totalCnt - (this.viewCnt * this.page));
    }
}