// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mypage_MyPageContainer__42xcd {
  margin-top: 2rem;
  display: flex;
}

.mypage_MyPageLeft__Z\\+Shk {
  flex: 0 0 130px;
}

.mypage_LeftItem__m-ld2 {
  cursor: pointer;
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid #AAA;
}

.mypage_active__\\+Exep {
  background-color: var(--menu-color);
}

.mypage_MyPageMain__E-9o4 {
  flex: 1 1 auto;
  padding: 0 50px;
}

.mypage_MyPageMain__E-9o4 h3 {
  margin: 0;
  margin-bottom: 1rem;
}

.mypage_MyPageMain__E-9o4 table {
  width: 100%;
  table-layout: fixed;
  border: 1px solid #AAA;
  border-spacing: 0;
}

.mypage_MyPageMain__E-9o4 table tbody tr td {
  border-right: 1px solid #AAA;
  border-bottom: 1px solid #AAA;
  padding: 1rem;
}

.mypage_MyPageMain__E-9o4 table tbody tr td.mypage_name__60YFc {
  background-color: #EEE;
  text-align: center;
}

.mypage_MyPageMain__E-9o4 table tbody tr:last-child td {
  border-bottom: none;
}

.mypage_MyPageMain__E-9o4 table tbody tr td:last-child {
  border-right: none;
}

.mypage_PhoneWarn__90EdR {
  font-size: 0.6rem;
  margin: 0;
  margin-left: 2rem;
}

.mypage_MyPageMain__E-9o4 table tbody tr td input[type=text] {
  width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/pages/11.MyPage/mypage.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AACA;EACI,eAAA;EACA,kBAAA;EACA,YAAA;EACA,6BAAA;AAEJ;;AAAA;EACI,mCAAA;AAGJ;;AAAA;EACI,cAAA;EACA,eAAA;AAGJ;;AAAA;EACI,SAAA;EACA,mBAAA;AAGJ;;AAAA;EACI,WAAA;EACA,mBAAA;EACA,sBAAA;EACA,iBAAA;AAGJ;;AAAA;EACI,4BAAA;EACA,6BAAA;EACA,aAAA;AAGJ;;AACA;EACI,sBAAA;EACA,kBAAA;AAEJ;;AAAA;EACI,mBAAA;AAGJ;;AADA;EACI,kBAAA;AAIJ;;AADA;EACI,iBAAA;EACA,SAAA;EACA,iBAAA;AAIJ;;AACA;EACI,WAAA;AAEJ","sourcesContent":[".MyPageContainer {\n    margin-top: 2rem;\n    display: flex;\n}\n\n.MyPageLeft {\n    flex: 0 0 130px;\n}\n.LeftItem {\n    cursor: pointer;\n    text-align: center;\n    padding: 5px;\n    border-bottom: 1px solid #AAA;\n}\n.active {\n    background-color: var(--menu-color);\n}\n\n.MyPageMain {\n    flex: 1 1 auto;\n    padding: 0 50px;\n}\n\n.MyPageMain h3 {\n    margin: 0;\n    margin-bottom: 1rem;\n}\n\n.MyPageMain table {\n    width: 100%;\n    table-layout: fixed;\n    border: 1px solid #AAA;\n    border-spacing: 0;\n    \n}\n.MyPageMain table tbody tr td {\n    border-right: 1px solid #AAA;\n    border-bottom: 1px solid #AAA;\n    padding: 1rem;\n}\n\n\n.MyPageMain table tbody tr td.name {\n    background-color: #EEE;\n    text-align: center;\n}\n.MyPageMain table tbody tr:last-child td {\n    border-bottom: none;\n}\n.MyPageMain table tbody tr td:last-child {\n    border-right: none;\n}\n\n.PhoneWarn {\n    font-size: 0.6rem;\n    margin: 0;\n    margin-left: 2rem;\n}\n\n\n\n.MyPageMain table tbody tr td input[type=text] {\n    width: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MyPageContainer": `mypage_MyPageContainer__42xcd`,
	"MyPageLeft": `mypage_MyPageLeft__Z+Shk`,
	"LeftItem": `mypage_LeftItem__m-ld2`,
	"active": `mypage_active__+Exep`,
	"MyPageMain": `mypage_MyPageMain__E-9o4`,
	"name": `mypage_name__60YFc`,
	"PhoneWarn": `mypage_PhoneWarn__90EdR`
};
export default ___CSS_LOADER_EXPORT___;
