import "./App.scss";
import AppRoute from "./pages/routes";


function App() {

    return (
        
           <AppRoute />
        
    );
}

export default App;


